import React, { createRef } from "react"
import { Card, Carousel, Icon } from "antd"
import StyledOfferings from './StyledOfferings';
import Familie from '../../images/offers/familie1.png';
import Krebs from '../../images/offers/krebs1.png';
import Kurativ from '../../images/offers/kurativ.png';
import Schwanger from '../../images/offers/schwanger1.png';
import Spritze from '../../images/offers/spritze1.png';

const offersConfig = [
  {
    title: 'Schutzimpfungen',
    subtitle: 'Grundimmunisierung und Auffrischung:',
    bulletPoints: [
      'Tetanus-/ Diphtherie-/ Polio-/ Pertussis- (= Keuchhusten)-Impfung',
      'HPV-Impfung (Kassenleistung 9 - 17, ab 18 je nach Krankenkasse)',
      'Masern-/Mumps-/Röteln-Impfung',
      'Varizellen-(Windpocken)-lmpfung (Kassenleistung bei Nachweis fehlender Immunität)',
      'Hepatitis-B-Impfung (Kassenleistung bis 18. Lj.)',
      'Influenza-(Grippe)-, Pneumokokken-Impfung (> 60 Jahre bzw. bei chronischer Grunderkrankung)',
      'Reise-Impfungen (z.B. Hepatitis A/B, FSME, Typhus, Cholera u.a.)',
    ],
    image: Spritze
  },
  {
    title: 'Krebsfrüherkennung',
    bulletPoints: [
      'Gynäkologische Untersuchung/ Zellabstrich',
      'Brustuntersuchung',
      'Rektale Untersuchung (ab 50 Jahre)',
      'Immunologischer Stuhltest (ab 50 Jahre)',
      'HPV-Test (nach auffälligem Zellabstrich)',
      'Dünnschicht-Zytologie/ ThinPrep®',
      'Vaginalsonographie (Ultraschall Unterleib)',
      'Mammasonographie (Ultraschall Brust)',
    ],
    image: Krebs
  },
  {
    title: 'Schwangerschaft',
    bulletPoints: [
      'Gynäkologische Untersuchung zur Feststellung einer Schwangerschaft',
      'Beratung und Vorsorgeuntersuchungen incl. 3 Ultraschalluntersuchungen entsprechend den Mutterschaftsrichtlinien',
      'Kardiotokographie (CTG)',
      'Ersttrimester-Screening (Berechnung des Fehlbildungsrisikos durch Messung der fetalen Nackentransparenz)',
      '3D-/ 4-D Ultraschalluntersuchungen',
      'Bluttest auf Toxoplasmose-Immunität',
      'Diabetes-Suchtest (Giukosetoleranztest)',
      'Vaginalabstrich auf B-Streptokokken-Besiedlung',
    ],
    image: Schwanger
  },
  {
    title: 'Familienplanung',
    bulletPoints: [
      'Kinderwunschbehandlung',
      'Beratung zu allen Formen der Empfängnisverhütung',
      'Gynäkologische Untersuchung/Zellabstrich (halbjährlich)',
      'Verordnung der Pille, ggf. auch zur Behandlung von Akne oder Haarausfall',
      'Verordnung der Dreimonatsspritze',
      'Bluttest auf Röteln-/Windpocken-Immunität',
      'Einlage eines Mirena®-/ Kyleena®-/ Jaydess®-Intrauterinsystems',
      'Einlage eines Implanon®-Depotstäbchens',
      'Injektion einer Dreimonatsspritze',
      'Einlage einer Kupferspirale',
    ],
    image: Familie
  },
  {
    title: 'Kurative Leistungen',
    bulletPoints: ['Endokrinologie: Untersuchung und Beratung in allen Fragen zum weiblichen Hormonhaushalt'],
    image: Kurativ
  }
];

const Offerings = () => {
  let counter = 0;
  const seperated = offersConfig.reduce((acc, curr, idx) => {
    if (idx !== 0 && idx % 2 === 0) counter++;
    if (!acc[counter]) acc.push([]);
    acc[counter].push(curr);
    return acc;
  }, []);

  let carousel = createRef();

  return (
    <StyledOfferings>
      <h2>Unsere Leistungen</h2>
        <div className="desktop">
        <Icon className="left-handle" type="left" onClick={() => carousel.prev()}/>
        <Carousel ref={node => (carousel = node)}>
          {seperated.map((offerItem, idx) => (
            <div>
              <div key={idx} className="card-container">
                {offerItem.map(offer => (
                  <Card key={offer.title} cover={<img alt={offer.title} src={offer.image} />}>
                    <Card.Meta title={offer.title} description={offer.subtitle ? offer.subtitle : null} />
                    <ul>
                      {offer.bulletPoints.map(bp => (
                        <li>{bp}</li>
                      ))}
                    </ul>
                  </Card>
                ))}
              </div>
            </div>
          ))}
        </Carousel>
        <Icon className="right-handle" type="right" onClick={() => carousel.next()}/>
      </div>
      <div className="phone">
        <div className="card-container">
        <Carousel>
            {offersConfig.map(offer => (
              <div>
                <Card key={offer.title} cover={<img alt={offer.title} src={offer.image} />}>
                  <Card.Meta title={offer.title} description={offer.subtitle ? offer.subtitle : null} />
                  <ul>
                    {offer.bulletPoints.map(bp => (
                      <li>{bp}</li>
                    ))}
                  </ul>
                </Card>
              </div>
            ))}
        </Carousel>
        </div>
      </div>
    </StyledOfferings>
  )
};

export default Offerings;