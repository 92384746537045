import React from "react";
import { Icon, Menu } from 'antd';
import StyledMenu from './StyledMenu';

const menu = [
  { icon: 'home', label: 'Home', link: '#home' },
  { icon: 'global', label: 'Aktuelles', link: '#aktuelles' },
  { icon: 'shop', label: 'Praxis', link: '#praxis' },
  { icon: 'team', label: 'Team', link: '#team' },
  { icon: 'medicine-box', label: 'Leistungen', link: '#leistungen' },
  { icon: 'calendar', label: 'Doctolib Apps', link: '#termine' },
  { icon: 'mail', label: 'Kontakt', link: '#kontakt' },
]

const MainMenu = () => (
  <StyledMenu
    defaultSelectedKeys={['1']}
    mode="vertical"
  >
    {menu.map(menuItem => (
      <Menu.Item key={menuItem.label}>
        <a href={menuItem.link}>
          <Icon type={menuItem.icon} />
          {menuItem.label}
        </a>
      </Menu.Item>
    ))}
  </StyledMenu>
);



export default MainMenu;
