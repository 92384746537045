import styled from 'styled-components';

const StyledFooter = styled.div`
  color: white;
  
  .footer-container {
    display: flex;
    justify-content: space-between;
    
    ${({theme}) => theme.media.phone`flex-direction: column`};
  }
  
  
  h2 {
    color: white;
    padding: ${({theme}) => theme.s} 0;
  }
  
  .footer__address {
    display: flex;
  }
  
  .footer__address,
  .footer__phone,
  .footer__fax {
    span {
      margin-left: 1rem;
    }
  }
  
  .footer__links {
      margin-top: 2rem;
      display: flex;
    
    a {
      margin-right: 1rem;
    }
    
    span {
      text-decoration: underline;
    }
  }
  
  
  .footer__map {
    margin-right: -1rem;
    ${({ theme }) => theme.media.phone`
      margin-left: -1rem;
      margin-top: 1rem;
    `};
  }
  `;

export default StyledFooter;