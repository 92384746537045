import { css } from 'styled-components';

const sizes = {
  desktop: 991,
  tablet: 767,
  phone: 575,
};

export default {
  primaryColor: '#f39200',

  grey: '#efefef',

  xxs: '0.2rem',
  xs: '0.5rem',
  s: '1rem',
  m: '1.5rem',
  l: '2rem',

  headerHeightExpanded: '5.4rem',
  headerHeightCollapsed: '3.4rem',

  media: Object.keys(sizes).reduce((acc, size) => {
    acc[size] = (...args) => css`
      @media only screen and (max-width: ${sizes[size]}px) {
        ${css(...args)}
      }
    `
    return acc;
  }, {})
}