import styled from 'styled-components';

const StyledPraxis = styled.div`
position: relative;

.left-handle { left: 0; }
.right-handle { right: 0; }

.left-handle,
.right-handle {
  width: 3rem;
  position: absolute;
  top: 0;
  height: 100%;
  cursor: pointer;
  
   ${({ theme }) => theme.media.phone`display: none`};
  
  &:hover .left-handle__white-bg,
  &:hover .right-handle__white-bg {
      opacity: 0.5;
    }
  
  &__white-bg {
    background-color: white;
    opacity: 0;
    height: 100%;
    width: 100%;
    transition: 400ms opacity;
  }
}

i {
  color: white;
  font-size: 3rem;
  position: absolute;
  top: 50%;
}
`;

export default StyledPraxis;