import React, { createRef } from "react"
import { Carousel, Icon } from "antd"
import StyledPraxis from './StyledPraxis';
import JSONData from '../../config';

const Praxis = () => {
  let carousel = createRef();

  return (
  <StyledPraxis>
    <div className="inner-wrapper">
      <h2>Unsere Praxis</h2>
    </div>
    <Carousel ref={node => (carousel = node)}>
      {JSONData.praxisConfig.map(pic => <img src={pic} />)}
    </Carousel>
    <div className="left-handle" onClick={() => carousel.prev()}>
      <div className="left-handle__white-bg"  />
      <Icon className="left-handle__icon" type="left" />
    </div>
    <div className="right-handle" onClick={() => carousel.next()}>
      <div className="right-handle__white-bg"  />
      <Icon className="right-handle__icon" type="right" />
    </div>
  </StyledPraxis>
)};

export default Praxis;