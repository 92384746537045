import React from 'react';
import StyledHome from './StyledHome';
import { Button } from 'antd';

const Home = () => (
  <StyledHome className="home">
      <div className="home-overlay headline">
        <div className="home-overlay__bg" />
        <div className="home-overlay__content">
          <h2>
            Gemeinschaftspraxis für Frauenheilkunde und Geburtshilfe
          </h2>
        </div>
      </div>
    <Button shape="circle" href="#usps" icon="down" type="primary" size="large" />
  </StyledHome>


)

export default Home;