import styled from 'styled-components';

const StyledOfferings = styled.div`

  h2 {
    margin-bottom: 3.5rem;
    ${({ theme }) => theme.media.phone`margin-bottom: 0.5rem`};
  }
  
  i {
    font-size: 1.3rem;
    position: absolute;
    top: calc(50% - 0.6rem);
    z-index: 5;
    
    &.left-handle {
      left: 1rem;
    }
    
    &.right-handle {
      right: 1rem;
    }
  }

  .card-container {
    display: flex;
    justify-content: space-around;
    padding: 0 1.8rem ${({theme}) => theme.l};
     ${({ theme }) => theme.media.phone`
      display: block;
      padding: 0;
    `};
  }
  
  .ant-card{
   width: 45%;
   
   ${({theme}) => theme.media.phone`
      width: calc(100% - 1rem);
      margin: 0 0.5rem;
   `}
   
   &:nth-child(odd) {
    margin-left: 1rem;
    ${({ theme }) => theme.media.phone`margin-left: 0.5rem`};
   }

   &:nth-child(even) {
    margin-right: 1rem;
    ${({ theme }) => theme.media.phone`margin-left: 0.5rem`};
   }
   
   ul {
    padding-left: 2rem;
    margin-top: 0.5rem;
   }
   
   img {
    max-width: 35%;
    height: auto;
    margin: 1rem auto 0.2rem;
   }
  } 
  
   .ant-carousel .slick-dots li button,
   .ant-carousel .slick-dots li.slick-active button {
      ${({ theme }) => theme.media.phone`
        background-color: black;
      `};
    }
`;

export default StyledOfferings;