import React from 'react';
import { Button, Icon } from "antd";
import StyledAppointments from './StyledAppointments';
import Devices from '../../images/devices_doctolib.png';

const Appointments = () => (
  <StyledAppointments>
    <h2>Verwalten Sie Ihre Termin auch von unterwegs!</h2>
    <img src={Devices} />
    <div className="link-container">
      <div className="link-container__description">
        Ganz einfach über die Doctolib App.
      </div>
      <Button
        href="https://apps.apple.com/us/app/doctolib/id925339063"
        target="_blank"
      >
        Hier geht's zum <b>App-Store</b>
        <Icon type="right" />
      </Button>
      <Button
        href="https://play.google.com/store/apps/details?id=fr.doctolib.www&hl=de"
        target="_blank"
      >
        Hier geht's zum <b>Play-Store</b>
        <Icon type="right" />
      </Button>
    </div>
  </StyledAppointments>
);

export default Appointments;
