import styled from 'styled-components';

const StyledOpeningHours = styled.div`
${({theme}) => theme.media.phone`padding: 1rem`};
.opening-hour {
  line-height: 1.7rem;
      
    &__day {
      padding-right: ${({theme}) => theme.s};
      font-weight: bold;
    }
}
  
.ant-divider {
  ${({theme}) => theme.media.phone`margin: 0.7rem 0;`};
}
    
.opening-hours {
    &__disclaimer {
      margin-bottom: ${({theme}) => theme.xs};
    
      &-title {
        line-height: 1.7rem;
        font-weight: bold;
      }
      
     
      &-days {
        padding-right: ${({theme}) => theme.xs};
      }
    }
}
  

`;

export default StyledOpeningHours;