import React from 'react';
import { Icon, Button, Popover } from 'antd';
import StyledHeader from './StyledHeader';
import OpeningHours from '../OpeningHours/OpeningHours';
import Logo from '../../images/logo.png';

const Header = ({ onMenuClick, setMobileOpeningHoursOpen }) => {
  return (
    <StyledHeader className="header">
      <div className="header-main">
        <div className="header-main__menu">
          <Icon className="header-main__menu-handle" type="menu" onClick={() => {
            setMobileOpeningHoursOpen(o => o && !o);
            onMenuClick(o => !o);
          }
          } />
          <img src={Logo} />
        </div>
        <div className="desktop">
          <Popover content={<OpeningHours />} placement="bottom" trigger="hover">
            <Button type="link">Sprechzeiten</Button>
          </Popover>
          <Button href="#kontakt" type="link">Kontakt</Button>
        </div>
        <Button className="phone" type="link" onClick={() => {
          setMobileOpeningHoursOpen(o => !o);
          onMenuClick(o => o && !o);
        }}>Sprechzeiten</Button>
      </div>
    </StyledHeader>
  )
};

export default Header;
