import React, { createRef } from "react"
import StyledTeam from './StyledTeam';
import { Card, Carousel, Icon } from "antd"
import JSONData from '../../config';

const Team = () => {
  let counter = 0;
  const separated = JSONData.teamConfig.reduce((acc, curr, idx) => {
    if (idx !== 0 && idx % 3 === 0) counter++;
    if (!acc[counter]) acc.push([]);
    acc[counter].push(curr);
    return acc;
  }, []);

  let carousel = createRef();

  return (
    <StyledTeam>
      <h2>Unser Team</h2>
      <div className="desktop">
        <Icon className="left-handle" type="left" onClick={() => carousel.prev()}/>
        <Carousel ref={node => (carousel = node)}>
          {separated.map(team => (
            <div>
              <div className="card-container">
                {team.map(member => (
                  <Card
                    key={member.name}
                    cover={<img alt={member.name} src={member.image} />}
                  >
                    <Card.Meta title={member.name} description={member.title} />
                  </Card>
                ))}
              </div>
            </div>
          ))}
        </Carousel>
        <Icon className="right-handle" type="right" onClick={() => carousel.next()}/>
      </div>
      <div className="phone">
        <Carousel>
          {JSONData.teamConfig.map(member => (
            <Card
              key={member.name}
              cover={<img alt={member.name} src={member.image} />}>
              <Card.Meta title={member.name} description={member.title} />
            </Card>
          ))}
        </Carousel>
      </div>
    </StyledTeam>
  )
}

export default Team;
