import styled from 'styled-components';

const StyledAppointments = styled.div`
display: flex;
flex-wrap: wrap;
justify-content: space-between;

h2 {
width: 100%;
}

img {
  max-width: 50%;
  height: auto;
  ${({ theme }) => theme.media.phone`display: none`};
}

.link-container {
  max-width: 33%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 1rem;
  margin-right: 1rem;
  ${({ theme }) => theme.media.phone`max-width: 100%`};
  
  &__description {
    margin-bottom: 1rem;
  }
  
  .ant-btn {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 0.5rem;
    
    i {
      margin-left: 0.5rem;
    }
  }
  
  b {
    margin-left: 0.2rem;
  }
}
`;

export default StyledAppointments;