import styled from 'styled-components';

const StyledHome = styled.div`
  height: calc(90vh - 3.4rem);
  position: relative;
  max-width: 1024px;
  
  ${({ theme }) => theme.media.phone`height: 5.5rem`};
  
  a {
    position: absolute;
    bottom: 0.7rem;
    left: 50%;
    
    &.ant-btn {
      background-color: black;
      border-color: black;
      opacity: 0.5;
      
      &:hover {
        opacity: 0.7;
      }
    }
  }
  
  .home-overlay {
  
    &.headline {
      position: absolute;
      left: 0;
      top: 0;
      
      h2 {
        margin: 0;
      }
    }
    
    &.link {
      right: 0;
      bottom: ${({theme}) => theme.l};
      position: absolute;
    }
    
    &__bg {
      background-color: white;
      opacity: 0.7;
      height: 100%;
      width: 100%;
      position: absolute;
      z-index: 5;
      top: 0;
      left: 0;
    }
    
    &__content {
      position: relative;
      z-index: 10;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      padding: ${({theme}) => `${theme.xs} ${theme.s}`};
      overflow: hidden;
      
      button {
        float: right;
      }
    }
  }
  
`;

export default StyledHome

