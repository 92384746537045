import styled from 'styled-components';

const StyledUsp = styled.div`
 background-color: white;
 display: flex;
 justify-content: space-around;
 padding: 10vh 0 5vh;
 
 ${({theme}) => theme.media.phone`
    display: none;
 `}
 
 .usp-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 30%;
  
    &__title {
      font-weight: bold;
      margin: ${({theme}) => `${theme.l} 0 ${theme.s}`};
    }
    
    &__text {
      text-align: center;
    }
 }
`;

export default StyledUsp;