import React, { useState } from "react";
import { ThemeProvider } from "styled-components";
import Header from '../components/Header/Header';
import Body from '../components/Body/Body';
import { Helmet } from "react-helmet"

import defaultTheme from '../theme';

const IndexPage = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [mobileOpeningHoursOpen, setMobileOpeningHoursOpen] = useState(false);
  const [subMenuOpen, setSubMenuOpen] = useState(true);
  return (
    <ThemeProvider theme={defaultTheme}>
      <Helmet>
        <title>Dr. Brauns</title>
      </Helmet>
      <Header
        onMenuClick={setDrawerOpen}
        subMenuOpen={subMenuOpen}
        setSubMenuOpen={setSubMenuOpen}
        setMobileOpeningHoursOpen={setMobileOpeningHoursOpen}
      />
      <Body
        drawerOpen={drawerOpen}
        setDrawerOpen={setDrawerOpen}
        subMenuOpen={subMenuOpen}
        mobileOpeningHoursOpen={mobileOpeningHoursOpen}
        setMobileOpeningHoursOpen={setMobileOpeningHoursOpen}
      />
      <div
        style={{
          position: 'fixed',
          top: '200px',
          right: 0,
          display: 'block',
          textAlign: 'center',
          backgroundColor: '#0596DE',
          color: '#ffffff',
          fontSize: '14px',
          width: '100px',
          borderRadius: '4px0 0 4px',
          padding: '10px',
          zIndex: 999,
          boxShadow: '1px2px 4px rgba(0,0,0,0.2)',
          lineHeight: 1.4,
        }}>
        <span style={{
          fontSize: '14px',
          fontWeight: 'bold'
        }}>Termin<br />online buchen</span><br />
        <a style={{
          display: 'block',
          color: '#0596DE',
          textDecoration: 'none',
          padding: '5px',
          marginTop: '5px',
          background: '#fff',
          borderRadius: '4px',
        }} href="https://www.doctolib.de/frauenarzt/hameln/birgit-brauns?utm_campaign=website-button&amp;utm_source=birgit-brauns-website-button&amp;utm_medium=referral&amp;utm_content=option-8&amp;utm_term=birgit-brauns" target="_blank" rel="noopener noreferrer" >Frau Brauns</a>
        <a style={{
          display: 'block',
          color: '#0596DE',
          textDecoration: 'none',
          padding: '5px',
          marginTop: '5px',
          background: '#fff',
          borderRadius: '4px',
        }}
        href="https://www.doctolib.de/frauenarzt/hameln/karsten-brauns?utm_campaign=website-button&amp;utm_source=karsten-brauns-website-button&amp;utm_medium=referral&amp;utm_content=option-8&amp;utm_term=karsten-brauns" target="_blank" rel="noopener noreferrer" >Herr Dr. Brauns</a>
        <img style={{
          display: 'inline-block',
          height: '15px',
          marginTop: '10px',
          verticalAlign: 'middle',
          width: 'auto'
        }} src="https://www.doctolib.de/external_button/doctolib-white-transparent.png" alt="Doctolib" />
      </div>
    </ThemeProvider >
  )
}

export default IndexPage