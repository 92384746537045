import styled from "styled-components";
import homeBg from '../../images/home_bg.jpg';

const StyledBody = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding-top: 3.4rem;
  z-index: 5;
  background: url(${homeBg}) center 3.4rem no-repeat;
  background-size: cover;
  overflow: hidden;
  ${({ theme }) => theme.media.phone`background-image: none`};
  
  h2 {
    font-family: 'Helvetica', sans-serif;
    font-weight: bold;
  }
  
  .desktop {
    ${({ theme }) => theme.media.phone`display: none`};
  }
  
  .phone {
    display: none;
    ${({ theme }) => theme.media.phone`display: block`};
  }
  
  .nav {
    position: relative;
    height: 100%;
  }
  
  .ant-drawer-body {
     padding: 0;
     
     .ant-menu-item {
        padding-left: ${({theme}) => theme.m};
     }
  }
  
  .ant-drawer-left.ant-drawer-open .ant-drawer-content-wrapper {
    box-shadow: none;
  }
  
  .content {
    position: absolute;
    top: 3.4rem;
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100% - 3.4rem);
    width: 100%;
  }
  
  .wrapper {
    &-white {
      background-color: white;
    }
    
    &-black {
      background-color: black;
    }
    
    &-primary {
      margin: 0 -5px;
      background-color: ${({theme}) => theme.primaryColor};
      -webkit-box-shadow: inset 0 0 4px 1px rgba(0,0,0,0.5);
      -moz-box-shadow: inset 0 0 4px 1px rgba(0,0,0,0.5);
      box-shadow: inset 0 0 4px 1px rgba(0,0,0,0.5);
    }
    
    &-shadow {
      margin: 0 -5px;
      -webkit-box-shadow: inset 0 0 4px 1px rgba(0,0,0,0.5);
      -moz-box-shadow: inset 0 0 4px 1px rgba(0,0,0,0.5);
      box-shadow: inset 0 0 4px 1px rgba(0,0,0,0.5);
    }
    
    &-blurry {
    position: relative;
    overflow: hidden;
    
       &__blur {
         position: absolute;
         top: 0;
         left: 0;
         bottom: 0;
         right: 0;
         background: url(${homeBg}) center 3.4rem no-repeat;
         background-size: cover;
         filter: blur(4px);
         -webkit-filter: blur(4px);
         transform: scale(1.2) translate(-56px, -37px);
         ${({ theme }) => theme.media.phone`
          background-image: none;
         `};
       }
       
       &__head {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 3rem;
        background-color: white;
       }
    }
  }

  .inner-wrapper {
    max-width: 1024px;
    margin: 0 auto;
    padding: 0 ${({theme}) => theme.s};
    position: relative;
    width: 100%;
  }
  
  .to-top {
    position: fixed;
    right: calc(50% - 552px);
    bottom: 5rem;
    
    @media only screen and (max-width: 1140px) {
      right: 2rem;
    }
  }
`;

export default StyledBody;