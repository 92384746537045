import styled from 'styled-components';

const StyledHeader = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  z-index: 10;
  height: 3.4rem;
  overflow: hidden;
  background-color: white;
  
  .desktop {
    ${({ theme }) => theme.media.phone`display: none`};
  }
  
  .phone {
    display: none;
    ${({ theme }) => theme.media.phone`display: block`};
  }
    
  .header-main {
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${({theme}) => theme.grey};
    padding: ${({theme}) => `${theme.xs} ${theme.m}`};
    flex: 1 0 auto;
    justify-content: space-between;
    
    &__menu {
      display: flex;
      align-items: center;
      
       &-handle {
        font-size: ${({theme}) => theme.s};
        cursor: pointer;
      }
      
      img {
        height: 1.7rem;
        margin-left: ${({theme}) => theme.m};
        margin-top: -0.4rem;
      }
    }
    
   
  }
  
  .header-sub {
    color: white;
    
    button {
      float: right;
      
      
    }
    
  }
`;

export default StyledHeader;