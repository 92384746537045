import React from 'react';
import { Icon, Button } from 'antd';

import StyledFooter from './StyledFooter';

const Footer = () => {
  return (
    <StyledFooter>

        <div className="footer-container">
          <div>
            <h2>Kontakt</h2>
            <div className="footer__address">
              <div><Icon type="home" /></div>
              <span>
                Gemeinschaftspraxis Dr. Brauns <br/>
                Lohstraße 4 <br />
                31785 Hameln
              </span>
            </div>
            <div className="footer__mail">
              <Icon type="mail" />
              <Button href="mailto:mail@dr-brauns.de" type="link">mail@dr-brauns.de</Button>
            </div>
            <div className="footer__phone">
              <Icon type="phone" />
              <span>05151 - 41424</span>
            </div>
            <div className="footer__fax">
              <Icon type="printer" />
              <span>05151 - 92233</span>
            </div>
            <div className="footer__links">
              <Button target="_blank" href="/imprint" type="link">Impressum</Button>
              <Button target="_blank" href="/Datenschutzinformation_Praxis_Dr_Brauns.pdf" type="link">Datenschutzerklärung</Button>
            </div>
          </div>
          <div className="footer__map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2450.704320754011!2d9.364297915582227!3d52.10331257973747!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47ba8ef65732337f%3A0x6b6e04bd0b8da67c!2sDr.%20Karsten%20Brauns!5e0!3m2!1sde!2sde!4v1577460894819!5m2!1sde!2sde"
              width="600" height="450" frameBorder={0} style={{border: 0}} allowFullScreen=""
            />
          </div>
      </div>
    </StyledFooter>
  )
};

export default Footer;
