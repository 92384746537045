import styled from 'styled-components';

const StyledNews = styled.div`
  padding: ${({theme}) => theme.s} 0;
  
  h2 {
    margin-bottom: ${({theme}) => theme.s} 0;
  }
  
  i {
    font-size: 1.3rem;
    position: absolute;
    top: calc(50% - 0.6rem);
    z-index: 5;
    
    &.left-handle {
      left: 1rem;
    }
    
    &.right-handle {
      right: 1rem;
    }
  }
  
  .card-container {
    display: flex;
    padding: 0 1.8rem ${({theme}) => theme.l};
    justify-content: space-around;
    ${({ theme }) => theme.media.phone`display: block`};
  }
  
  .ant-card {
    -webkit-box-shadow: 0 0 4px 1px rgba(0,0,0,0.5);
    -moz-box-shadow: 0 0 4px 1px rgba(0,0,0,0.5);
    box-shadow: 0 0 4px 1px rgba(0,0,0,0.5);
    width: 32%;
    ${({ theme }) => theme.media.phone`
      margin: ${theme.xs};
      width: calc(100% - 1rem);
    `};
   
  }
  
  .ant-carousel .slick-dots-bottom {
    ${({ theme }) => theme.media.phone`bottom: -12px`};
  }
`;

export default StyledNews;