import React from "react"
import { Divider, Drawer, BackTop } from "antd";
import { document } from "browser-monads";
import StyledBody from './StyledBody';
import Menu from '../Menu/Menu';
import Home from '../Home/Home';
import Usps from '../USP/Usps';
import News from '../News/News';
import Team from '../Team/Team';
import Praxis from '../Praxis/Praxis';
import Offers from '../Offerings/Offerings';
import Appointments from '../Appointments/Appointments';
import Footer from '../Footer/Footer';
import OpeningHours from '../OpeningHours/OpeningHours';


const Body = ({ drawerOpen, setDrawerOpen, mobileOpeningHoursOpen, setMobileOpeningHoursOpen }) => {

  if (document) {
    document.querySelectorAll('a[href^="#"]').forEach(anchor => {
      anchor.addEventListener('click', function (e) {
        e.preventDefault();
        e.stopPropagation();
        if (drawerOpen) setDrawerOpen(false);

        document.querySelector(this.getAttribute('href')).scrollIntoView({
          behavior: 'smooth'
        });
      });
    });
  }

  return (
  <StyledBody>
    <div className="nav">
      <Drawer
        placement="left"
        closable={false}
        onClose={() => setDrawerOpen(false)}
        visible={drawerOpen}
        getContainer={false}
        style={{ position: 'absolute' }}
      >
        <Menu />
      </Drawer>
      <Drawer
        placement="top"
        closable={false}
        onClose={() => setMobileOpeningHoursOpen(false)}
        visible={mobileOpeningHoursOpen}
        getContainer={false}
        style={{ position: 'absolute' }}
      >
        <OpeningHours />
      </Drawer>
    </div>
      <div className="content">
          <div className="inner-wrapper">
            <a id="home" />
            <Home />
          </div>
          <div className="wrapper-white">
            <div className="inner-wrapper">
              <a id="usps" />
              <Usps />
            </div>
            <Divider />
            <div className="wrapper-primary">
              <div className="inner-wrapper">
                <a id="aktuelles" />
                <News />
              </div>
            </div>
            <Divider />
            <a id="praxis" />
            <Praxis/>
            <Divider />
            <div className="wrapper-shadow">
              <div className="inner-wrapper">
                <a id="team" />
                <Team />
              </div>
            </div>
            <Divider />
            <div className="wrapper-blurry">
              <div className="wrapper-blurry__blur" />
              <div className="wrapper-blurry__head"/>
              <div className="inner-wrapper">
                <a id="leistungen" />
                <Offers/>
              </div>
            </div>
            <Divider />
            <div className="wrapper-white">
              <div className="inner-wrapper">
                <a id="termine" />
                  <Appointments />
              </div>
            </div>
            <div className="wrapper-black">
              <div className="inner-wrapper">
                <a id="kontakt" />
                <Footer />
              </div>
            </div>
          </div>
        <a href="#home">
          <BackTop className="to-top" visible />
        </a>
        </div>
  </StyledBody>
)};

export default Body;
