import React, { createRef } from 'react';
import { Card, Carousel, Icon } from 'antd';
import StyledNews from './StyledNews';
import JSONData from '../../config';

const News = () => {
  let counter = 0;
  const cardSeparation = JSONData.newsConfig.reduce((acc, curr, idx) => {
    if (idx !== 0 && idx % 3 === 0) counter++;
    if (!acc[counter]) acc.push([]);
    acc[counter].push(curr);
    return acc;
  }, []);

  let carousel = createRef();

  return (
    <StyledNews>
      <h2>Aktuelles</h2>
      <div className="desktop">
        {(cardSeparation.length > 1) && <Icon className="left-handle" type="left" onClick={() => carousel.prev()}/>}
        <Carousel ref={node => (carousel = node)}>
          {cardSeparation.map((news, idx) => (
            <div>
              <div className="card-container" key={idx}>
                {news.map(news => (
                  <Card
                    key={news.title}
                    title={news.title}
                    extra={
                      news.link
                        ? <a href={news.link} target="_blank">Mehr...</a>
                        : null}>
                    {news.segments.map(text => <p>{text}</p>)}
                  </Card>
                ))}
              </div>
            </div>
          ))}
        </Carousel>
        {(cardSeparation.length > 1) && <Icon className="right-handle" type="right" onClick={() => carousel.next()} />}
      </div>
      <div className="phone">
        <div className="card-container">
          <Carousel>
            {JSONData.newsConfig.map(news => (
              <div>
                <Card
                  key={news.title}
                  title={news.title}
                  extra={
                    news.link
                      ? <a href={news.link} target="_blank">Mehr...</a>
                      : null
                  }>
                  {news.segments.map(
                    (text) => (
                      <p>
                        {text}
                      </p>
                    )
                  )}
                </Card>
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    </StyledNews>
  )
};

export default News;
