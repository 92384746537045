import styled from 'styled-components';

const StyledTeam = styled.div`
  padding: ${({theme}) => theme.s} 0;
  
  i {
    font-size: 1.3rem;
    position: absolute;
    top: calc(50% - 0.6rem);
    z-index: 5;
    
    &.left-handle {
      left: 1rem;
    }
    
    &.right-handle {
      right: 1rem;
    }
  }
  
  .card-container {
    display: flex;
    justify-content: space-around;
    padding: 0 1.8rem;
    
    .ant-card {
      width: 30%;
      margin-bottom: ${({theme}) => theme.m};
    }
  }
  
    .ant-carousel .slick-dots li button,
    .ant-carousel .slick-dots li.slick-active button {
      background-color: black;
    }
`;

export default StyledTeam;