import React from 'react';
import StyledOpeningHours from './StyledOpeningHours';
import { Divider } from "antd";
import JSONData from '../../config.json';

const OpeningHours = () => (
  <StyledOpeningHours className="opening-hours">
    <table>
      <tbody>
        {JSONData.openingHoursConfig.map(openingHour => (
          <tr className="opening-hour" key={openingHour.day}>
            <td className="opening-hour__day">{openingHour.day}</td>
            <td className="opening-hour__time">{openingHour.time}</td>
          </tr>
        ))}
      </tbody>
    </table>
    <Divider />
    <div className="opening-hours__disclaimer">
      <div className="opening-hours__disclaimer-title">Offene Sprechstunde:</div>
      <span className="opening-hours__disclaimer-days">Mo - Fr</span> 12-13 Uhr
    </div>
  </StyledOpeningHours>
);

export default OpeningHours;
