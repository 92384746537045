import React from 'react';
import StyledUsp from './StyledUsp';
import Freundlich from '../../images/freundlich.jpg';
import Individuell from '../../images/individuell.jpg';
import Kompetent from '../../images/kompetent.jpg';


const uspConfig = [
  { image: Freundlich,
    title: 'Freundlich',
    text: 'Haben Sie Fragen? Mit offenem Ohr lauschen wir Ihren Problemen und kümmern uns gerne um Ihre Anliegen.'
  }, {
    image: Individuell,
    title: 'Individuell',
    text: 'Gesundheit ist für jeden ein individuelles Thema, deshalb kümmern wir uns auch individuell um unsere Patienten.'
  },
  {
    image: Kompetent,
    title: 'Kompetent',
    text: 'Mit unserer vollen Fachkompetenz stehen wir Ihnen gerne bei jedem Thema zur Seite.'
  },
]

const USPItem = ({ image, title, text }) => (
  <div className="usp-item">
    <img src={image} />
    <h2 className="usp-item__title">{title}</h2>
    <div className="usp-item__text">{text}</div>
  </div>
)

const USPs = () => (
  <StyledUsp className="usp">
    {uspConfig.map(conf => (
      <USPItem key={conf.title} image={conf.image} title={conf.title} text={conf.text} />
    ))}
  </StyledUsp>
)

export default USPs;